import { gql, useMutation } from "@apollo/client";

export const ACTIONS_DETAILS_MUTATION = gql`
  mutation MyMutation($input: BillingActionInput!) {
    orderItemBillingAction(input: $input) {
      orderId
      nmbsBillingDetails {
        billingStatus
        invoiceId
        billInitiatedAt
      }
    }
  }
`;

export const useBillingActionsUpdate = () => {
  const mutation = useMutation(ACTIONS_DETAILS_MUTATION);
  return mutation;
};
