import { gql, useMutation, useQuery } from "@apollo/client";

export const SYNC_WITH_SFDC_MUTATION = gql`
  mutation syncWithSFDC($id: ID!) {
    syncOrderItemWithSFDC(orderItemId: $id) {
      id
      subTotal
      quantity
      unitPrice
      classStartDate
      classEndDate
      metadata {
        key
        value
      }
    }
  }
`;

export const READ_FROM_SALESFORCE_QUERY = gql`
  query readFromSalesforce($id: String!) {
    getSalesforceOpportunityLineByLineItemId(opportunityLineItemId: $id) {
      opportunityLineItemId
      quantity
      productValueNetAmount
      classStartDate
      classEndDate
      contractingPTRR {
        awsEndUserAccount
        poNumberOnInvoice
        awsBillingAccount
      }
    }
  }
`;

export const useSyncOrderDetailsWithSFDCMutation = (orderItemId: string) => {
  const mutation = useMutation(SYNC_WITH_SFDC_MUTATION, {
    variables: { id: orderItemId },
    onError: () => {},
  });
  return mutation;
};

export const useReadFromSalesforceQuery = (opportunityLineItemId: string) => {
  const query = useQuery(READ_FROM_SALESFORCE_QUERY, {
    variables: { id: opportunityLineItemId },
    onError: () => {},
  });
  return query;
};
