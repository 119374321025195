import { Box, ColumnLayout, Container, Header, SpaceBetween, Button, Modal, Alert } from "@amzn/awsui-components-react";
import { useIntl, FormattedDate } from "react-intl";
import { Order } from "../types";
import { useEffect, useState } from "react";
import { orderSyncWithSFDCEnabled } from "../../common/featureFlags";
import { useReadFromSalesforceQuery, useSyncOrderDetailsWithSFDCMutation } from "../api/useSyncOrderDetailsWithSFDC";
import { getMetadataField } from "../constants";

interface OrderProps {
  order: Order;
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

export function DetailsTable(props: OrderProps): JSX.Element {
  const intl = useIntl();
  const [showProgress, setShowProgress] = useState(false);
  const isSyncWithSFDCEnabled = orderSyncWithSFDCEnabled();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [sfdcError, setSfdcError] = useState<string | null>(null);
  const [isSyncButtonReady, setIsSyncButtonReady] = useState(false);
  const [itemToSync, setItemToSync] = useState<{
    current: (typeof props.order.items)[0];
    sfdc: any;
  } | null>(null);
  const sfdcQuery = useReadFromSalesforceQuery(props.order.items[0].opportunityLineItemId);

  useEffect(() => {
    if (!sfdcQuery.loading) {
      setIsSyncButtonReady(true);
    }
  }, [sfdcQuery.loading]);

  const [syncWithSFDC] = useSyncOrderDetailsWithSFDCMutation(props.order.items[0].id);

  const handleAction = async () => {
    try {
      const sfdcItem = sfdcQuery?.data?.getSalesforceOpportunityLineByLineItemId;
      const currentItem = props.order.items[0];
      setItemToSync({ current: currentItem, sfdc: sfdcItem });
      setShowProgress(true);
      setShowConfirmationModal(true);
      setSfdcError(null);
    } catch (error) {
      setSfdcError(intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.button.error" }));
      setShowConfirmationModal(false);
      setShowProgress(false);
    }
  };

  const handleConfirmSync = async () => {
    setShowConfirmationModal(false);
    try {
      await syncWithSFDC();
      window.location.reload();
      setSfdcError(null);
    } catch (error) {
      setSfdcError(intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.error" }));
    } finally {
      setItemToSync(null);
      setShowProgress(true);
    }
    setShowProgress(false);
  };

  const handleCancelSync = async () => {
    setShowConfirmationModal(false);
    setItemToSync(null);
    setShowProgress(false);
  };

  return (
    <div>
      <Container
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Header data-testid="order-details-table-header" variant="h2">
              {intl.formatMessage({ id: "orderDetailsComponent.header.orderDetails" })}
            </Header>
            {isSyncWithSFDCEnabled && (
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  onClick={() => {
                    handleAction();
                  }}
                  disabled={showProgress || !isSyncButtonReady}
                  variant="primary"
                  wrapText={false}
                  data-testid="sync-with-sfdc"
                >
                  {showProgress
                    ? intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.button.inProgress" })
                    : intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.button.default" })}
                </Button>
              </div>
            )}
          </div>
        }
      >
        {sfdcError && (
          <Alert onDismiss={() => setSfdcError(null)} visible={true} dismissible={true} type="error" header="Error">
            {sfdcError}
          </Alert>
        )}
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.customerName" })}>
              <div data-testid={"learningAccount-name"}>{props.order.learningAccount.name}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.orderId" })}>
              <div data-testid={"order-id"}>{props.order.id}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.salesforceOpportunityId" })}>
              <div data-testid={"opprotunity-id"}>{props.order.opportunityId}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.billingAwsAccountId" })}>
              <div data-testid={"aws-billing-accountId"}>
                {props.order.billingAwsAccountId ? props.order.billingAwsAccountId : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderDetailsComponent.label.typeOfContract" })}>
              <div data-testid={"typeOfContract"}>
                {props.order.typeOfContract ? props.order.typeOfContract : "NEW"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
      {isSyncWithSFDCEnabled && (
        <Modal
          onDismiss={handleCancelSync}
          visible={showConfirmationModal}
          closeAriaLabel="Close modal"
          header={intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.header" })}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={handleCancelSync} data-testid="sync-with-sfdc-modal-cancel">
                  {intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.cancel" })}
                </Button>
                <Button variant="primary" onClick={handleConfirmSync} data-testid="sync-with-sfdc-modal-confirm">
                  {intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.confirm" })}
                </Button>
              </SpaceBetween>
            </Box>
          }
          data-testid="sync-with-sfdc-modal"
        >
          {
            <SpaceBetween size="s">
              <Box>
                <h4 style={{ fontSize: "1.2em", marginBottom: "2px", marginTop: "2px" }}>
                  {intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.opportunityLineItemIdLabel" })} :{" "}
                  {itemToSync?.sfdc?.opportunityLineItemId || "-"}
                </h4>
              </Box>
              <ColumnLayout columns={2}>
                <div>
                  <h4 style={{ fontSize: "1.2em", marginBottom: "10px" }}>
                    {intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.currentDataLabel" })}:
                  </h4>
                  <SpaceBetween size="s">
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.quantity" })}: </strong>
                      {itemToSync?.current?.quantity === 1
                        ? `${itemToSync?.current?.quantity} seat`
                        : `${itemToSync?.current.quantity} seats`}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.itemPrice" })}: </strong>
                      {intl.formatNumber(itemToSync?.current?.unitPrice ?? 0, { style: "currency", currency: "USD" })}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.total" })}: </strong>
                      {intl.formatNumber(itemToSync?.current?.subTotal ?? 0, { style: "currency", currency: "USD" })}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.classStartDate" })}: </strong>
                      {itemToSync?.current?.classStartDate ? (
                        <FormattedDate
                          value={itemToSync.current.classStartDate}
                          year="numeric"
                          month="long"
                          day="numeric"
                          timeZone="UTC"
                        />
                      ) : (
                        "-"
                      )}{" "}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.classEndDate" })}: </strong>
                      {itemToSync?.current?.classEndDate ? (
                        <FormattedDate
                          value={itemToSync.current.classEndDate}
                          year="numeric"
                          month="long"
                          day="numeric"
                          timeZone="UTC"
                        />
                      ) : (
                        "-"
                      )}{" "}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderBillingDetailsComponent.label.poNumber" })}: </strong>
                      {getMetadataField(itemToSync?.current, "poNumberOnInvoice")}
                    </Box>
                    <Box>
                      <strong>
                        {intl.formatMessage({ id: "orderBillingDetailsComponent.label.endUserAWSAccountId" })}:{" "}
                      </strong>
                      {getMetadataField(itemToSync?.current, "awsEndUserAccount")}
                    </Box>
                  </SpaceBetween>
                </div>
                <div>
                  <h4 style={{ fontSize: "1.2em", marginBottom: "10px" }}>
                    {intl.formatMessage({ id: "orderDetailsComponent.syncWithSFDC.modal.newDataFromSFDCLabel" })}:
                  </h4>
                  <SpaceBetween size="s">
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.quantity" })}: </strong>
                      {itemToSync?.sfdc?.quantity === 1
                        ? `${itemToSync?.sfdc?.quantity} seat`
                        : `${itemToSync?.sfdc?.quantity} seats`}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.itemPrice" })}: </strong>
                      {intl.formatNumber(itemToSync?.sfdc?.productValueNetAmount / itemToSync?.sfdc?.quantity, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.total" })}: </strong>
                      {intl.formatNumber(itemToSync?.sfdc?.productValueNetAmount ?? 0, {
                        style: "currency",
                        currency: "USD",
                      })}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.classStartDate" })}: </strong>
                      {itemToSync?.sfdc?.classStartDate ? (
                        <FormattedDate
                          value={itemToSync.sfdc.classStartDate}
                          year="numeric"
                          month="long"
                          day="numeric"
                          timeZone="UTC"
                        />
                      ) : (
                        "-"
                      )}{" "}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderItemsComponent.label.classEndDate" })}: </strong>
                      {itemToSync?.sfdc?.classEndDate ? (
                        <FormattedDate
                          value={itemToSync.sfdc.classEndDate}
                          year="numeric"
                          month="long"
                          day="numeric"
                          timeZone="UTC"
                        />
                      ) : (
                        "-"
                      )}{" "}
                    </Box>
                    <Box>
                      <strong>{intl.formatMessage({ id: "orderBillingDetailsComponent.label.poNumber" })}: </strong>
                      {itemToSync?.sfdc?.contractingPTRR?.poNumberOnInvoice || "-"}
                    </Box>
                    <Box>
                      <strong>
                        {intl.formatMessage({ id: "orderBillingDetailsComponent.label.endUserAWSAccountId" })}:{" "}
                      </strong>
                      {itemToSync?.sfdc?.contractingPTRR?.awsEndUserAccount || "-"}
                    </Box>
                  </SpaceBetween>
                </div>
              </ColumnLayout>
            </SpaceBetween>
          }
        </Modal>
      )}
    </div>
  );
}
