import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  ButtonDropdown,
  Modal,
  Button,
  FormField,
  Input,
  DatePicker,
  DatePickerProps,
  InputProps,
} from "@amzn/awsui-components-react";
import { FormattedDate, useIntl } from "react-intl";
import { B2BBillingStatus, OrderItem } from "../types";
import { ProductModality } from "../../onboarding/types";
import { useState } from "react";
import { billingActionsEnabled } from "../../common/featureFlags";
import React = require("react");
import { getMetadataField } from "../constants";
import { useBillingActionsUpdate } from "../api/useBillingActionsUpdate";

interface OrderProps {
  orderItem: OrderItem[];
}

const ValueWithLabel = (props: { label: any; children: any }) => (
  <div style={{ marginTop: "10px" }}>
    <Box variant="awsui-key-label">{props.label}</Box>
    <div>{props.children}</div>
  </div>
);

const myComponentStyle = {
  margin: "10px",
};

const convertBillingStatus = (billingStatus: B2BBillingStatus) => {
  switch (billingStatus) {
    case B2BBillingStatus.TO_BE_BILLED:
      return "To Be Billed";
    case B2BBillingStatus.BILL_IMMEDIATELY:
      return "Bill Immediately Via BC";
    case B2BBillingStatus.BILLED_VIA_CLIPPER_OCB:
      return "Billed Via Clipper OCB";
    case B2BBillingStatus.BILLING_IN_PROGRESS:
      return "Billing in Progress";
    case B2BBillingStatus.BILLED:
      return "Billed";
    case B2BBillingStatus.BILLING_FAILED:
      return "Billing Failed";
    case B2BBillingStatus.NOT_APPLICABLE:
      return "Not Applicable";
    case B2BBillingStatus.TO_BE_ACCRUED:
      return "To Be Accrued";
    case B2BBillingStatus.ACCRUED:
      return "Accrued";
  }
};

export const convertBillingActionId = (actionId: string) => {
  switch (actionId) {
    case "toBeBilled":
      return "TO_BE_BILLED";
    case "billImmediately":
      return "BILL_IMMEDIATELY";
    case "billClipper":
      return "BILLED_VIA_CLIPPER_OCB";
    case "toBeAccrued":
      return "TO_BE_ACCRUED";
    case "accrued":
      return "ACCRUED";
  }
};

export function BillingDetails(props: OrderProps): JSX.Element {
  const todaysDate = new Date().toISOString().slice(0, 10);
  const intl = useIntl();
  const [showProgress, setShowProgress] = useState(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [attemptedEntry, setAttemptedEntry] = useState(false);
  const orderItem = props.orderItem.filter((item) => item.productModality === ProductModality.DIGITAL)[0];
  const isBillingActionsEnabled = billingActionsEnabled();
  const [billingActionsUpdate] = useBillingActionsUpdate();
  const [billingStatus, setBillingStatus] = useState(orderItem.nmbsBillingDetails?.billingStatus);
  const [modalBillInitiatedAt, setModalBillInitiatedAt] = React.useState(todaysDate);
  const [itemBillInitiatedAt, setItemBillInitiatedAt] = React.useState(orderItem.nmbsBillingDetails?.billInitiatedAt);
  const [modalInvoiceId, setModalInvoiceId] = React.useState("");
  const [itemInvoiceId, setItemInvoiceId] = React.useState(orderItem.nmbsBillingDetails?.invoiceId);

  const handleAction = async (actionId: string, actionData?: { invoiceId: string; invoiceDate: string }) => {
    try {
      setShowProgress(true);
      setSelectedAction(actionId);

      // Create the input object based on the action type
      const billingActionInput = {
        orderItemId: orderItem.id,
        actionType: convertBillingActionId(actionId),
        ...(actionData && {
          invoiceDetail: {
            invoiceId: actionData.invoiceId,
            invoiceDate: new Date(actionData.invoiceDate).toISOString(),
          },
        }),
      };

      // Call the mutation with the proper input
      const result = await billingActionsUpdate({
        variables: {
          input: billingActionInput,
        },
      });

      setShowProgress(false);
      setSelectedAction(null);
      if (result?.data?.orderItemBillingAction?.nmbsBillingDetails?.billingStatus) {
        setBillingStatus(result.data.orderItemBillingAction.nmbsBillingDetails.billingStatus);
      }

      if (actionData) {
        setItemInvoiceId(modalInvoiceId);
        setItemBillInitiatedAt(modalBillInitiatedAt);
      }

      setShowProgress(false);
      setSelectedAction(null);
    } catch (error) {
      setShowProgress(false);
      setSelectedAction(null);
    }
  };

  const actionItems = [
    {
      id: "toBeBilled",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.toBeBilled.button" }),
    },
    {
      id: "billImmediately",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billImmediately.button" }),
    },
    {
      id: "billClipper",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.button" }),
    },
    {
      id: "toBeAccrued",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.toBeAccrued.button" }),
    },
    {
      id: "accrued",
      text: intl.formatMessage({ id: "orderBillingDetailsComponent.actions.accrued.button" }),
    },
  ];

  const handleActionDropdown = ({ detail }: { detail: { id: string } }) => {
    if (detail.id === "billClipper") {
      setIsModalVisible(true);
    } else {
      handleAction(detail.id);
    }
  };

  const handleModalDismiss = () => {
    setIsModalVisible(false);
  };

  const handleModalConfirm = () => {
    handleAction("billClipper", {
      invoiceId: modalInvoiceId,
      invoiceDate: modalBillInitiatedAt,
    });

    setIsModalVisible(false);
    setAttemptedEntry(false);
    // Reset the form
    setModalInvoiceId("");
    setModalBillInitiatedAt(todaysDate);
  };

  return (
    <div>
      <Container
        header={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Header
              data-testid="billing-details-table-header"
              variant="h2"
              actions={
                isBillingActionsEnabled && (
                  <SpaceBetween direction="horizontal" size="xs">
                    <ButtonDropdown
                      items={actionItems}
                      onItemClick={handleActionDropdown}
                      disabled={showProgress}
                      variant="primary"
                      data-testid="actions-button"
                    >
                      {selectedAction !== null
                        ? intl.formatMessage({
                            id: `orderBillingDetailsComponent.actions.${selectedAction}.inProgress`,
                          })
                        : intl.formatMessage({ id: "orderBillingDetailsComponent.actions.button" })}
                    </ButtonDropdown>
                  </SpaceBetween>
                )
              }
            >
              {intl.formatMessage({ id: "orderBillingDetailsComponent.header.billingDetails" })}
            </Header>
          </div>
        }
      >
        <ColumnLayout columns={3} variant="text-grid">
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.billingStatus" })}>
              <div data-testid="billingStatus">
                {orderItem && billingStatus ? convertBillingStatus(billingStatus) : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel
              label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.endUserAWSAccountId" })}
            >
              <div data-testid={"endUserAWSAccountId"}>{getMetadataField(orderItem, "awsEndUserAccount")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.poNumber" })}>
              <div data-testid={"poNumber"}>{getMetadataField(orderItem, "poNumberOnInvoice")}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.productName" })}>
              <div data-testid={"productName"}>{orderItem.productTitle}</div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceAmount" })}>
              <div data-testid={"invoiceAmount"}>
                {orderItem.subTotal && orderItem.nmbsBillingDetails?.billingStatus === B2BBillingStatus.BILLED
                  ? intl.formatNumber(orderItem.subTotal, { style: "currency", currency: "USD" })
                  : "-"}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceNumber" })}>
              <div data-testid={"invoiceNumber"}>
                {itemInvoiceId ? (
                  <Link href={orderItem.nmbsBillingDetails?.billingConsoleUILink} target="_blank">
                    {itemInvoiceId}
                  </Link>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.invoiceDate" })}>
              <div data-testid={"invoiceDate"}>
                {billingStatus === B2BBillingStatus.BILLED ? (
                  <span title={intl.formatDate(orderItem.nmbsBillingDetails?.billingStatusUpdatedAt)}>
                    <FormattedDate
                      value={orderItem.nmbsBillingDetails?.billingStatusUpdatedAt}
                      year="numeric"
                      month="long"
                      day="numeric"
                      timeZone="UTC"
                    />
                  </span>
                ) : billingStatus === B2BBillingStatus.BILLED_VIA_CLIPPER_OCB ? (
                  <span title={intl.formatDate(itemBillInitiatedAt, { timeZone: "UTC" })}>
                    <FormattedDate
                      value={itemBillInitiatedAt}
                      year="numeric"
                      month="long"
                      day="numeric"
                      timeZone="UTC"
                    />
                  </span>
                ) : (
                  "-"
                )}
              </div>
            </ValueWithLabel>
          </SpaceBetween>
          <SpaceBetween size="l">
            <ValueWithLabel label={intl.formatMessage({ id: "orderBillingDetailsComponent.label.errorMessage" })}>
              <div data-testid={"errorMessage"}>{orderItem.nmbsBillingDetails?.billingErrorMessage || "-"}</div>
            </ValueWithLabel>
          </SpaceBetween>
        </ColumnLayout>
      </Container>
      <Modal
        onDismiss={handleModalDismiss}
        visible={isModalVisible}
        closeAriaLabel="Close modal"
        header={intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.header" })}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={handleModalDismiss} data-testid="cancel-button">
                {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.cancel" })}
              </Button>
              <Button
                variant="primary"
                onClick={handleModalConfirm}
                disabled={!modalInvoiceId}
                data-testid="save-button"
              >
                {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.save" })}
              </Button>
            </SpaceBetween>
          </Box>
        }
        data-testid="clipper-modal"
      >
        <SpaceBetween size="m">
          <Box variant="p" data-testid="get-invoice-information-box">
            {intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.getInvoiceInformation" })}
          </Box>
          <FormField
            label={intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceIdLabel" })}
            errorText={
              !modalInvoiceId && attemptedEntry
                ? intl.formatMessage({ id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceIdNotGiven" })
                : undefined
            }
            data-testid="get-invoice-id"
          >
            <Input
              value={modalInvoiceId}
              onChange={({ detail }: { detail: InputProps.ChangeDetail }) => {
                setAttemptedEntry(true);
                setModalInvoiceId(detail.value);
              }}
              placeholder={intl.formatMessage({
                id: "orderBillingDetailsComponent.actions.billClipper.modal.getInvoiceId",
              })}
              type="text"
            />
          </FormField>
          <FormField
            label={intl.formatMessage({
              id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceDateLabel",
            })}
            constraintText={intl.formatMessage({
              id: "orderBillingDetailsComponent.actions.billClipper.modal.invoiceDateFormat",
            })}
            data-testid="get-invoice-date"
          >
            <DatePicker
              onChange={({ detail }: { detail: DatePickerProps.ChangeDetail }) => {
                setModalBillInitiatedAt(detail.value);
              }}
              value={modalBillInitiatedAt}
              placeholder="YYYY/MM/DD"
              data-testid="invoice-date-picker"
              isDateEnabled={(date: { getFullYear: () => number; getMonth: () => number; getDate: () => number }) => {
                const inputDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

                // Get current UTC date (set to midnight UTC)
                const today = new Date(todaysDate);
                const utcToday = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));

                return inputDate <= utcToday;
              }}
            />
          </FormField>
        </SpaceBetween>
      </Modal>
    </div>
  );
}
